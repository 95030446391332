import { ArrowUp } from "lucide-react";
import { useEffect, useState } from "react";

interface Props {}

const BackToTop = ({}: Props) => {
  const [showBackToTop, setShowBackToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 300);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {showBackToTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 bg-kp-accent text-kp-black p-3 rounded-full shadow-lg hover:bg-kp-accent hover:text-kp-black transition-colors z-50"
          aria-label="Back to top">
          <ArrowUp size={24} />
        </button>
      )}
    </>
  );
};

export default BackToTop;
